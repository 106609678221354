'use client'

import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'

export const FacebookPixelEvents = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const facebookPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(facebookPixelId)
        ReactPixel.pageView()
      })
  }, [pathname, searchParams])

  return null
}
